<template>
  <div class="mt-10">
    <h1 class="section__headline">Informes</h1>
    <!--begin::Pedidos-->
    <v-container>
      <v-row class="justify-content-center">
        <div class="col-xxl-8 order-2 order-xxl-1">
          <v-card flat class="card card-custom card-stretch gutter-b">
            <v-card-title class="font-weight-bolder text-dark table__header">
              <div>
                Generación de informes (actualmente sólo informe detallado)
              </div>
            </v-card-title>
            <div class="card-body pt-0 pb-3 d-flex justify-content-around">
              <div>
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                  offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fecha_inicio_formatted" label="Fecha de inicio" hint="DD/MM/YYYY"
                      persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" @blur="date = parseDate(dateFormatted)"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker locale="ES" first-day-of-week="1" v-model="fecha_inicio" no-title
                    @input="menu1 = false"></v-date-picker>
                </v-menu>
                <!-- <p>Date in ISO format: <strong>{{ date }}</strong></p> -->
              </div>

              <div>
                <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
                  max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fecha_fin_formatted" label="Fecha de fin del periodo" hint="DD/MM/YYYY"
                      persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker locale="ES" first-day-of-week="1" v-model="fecha_fin" no-title
                    @input="menu2 = false"></v-date-picker>
                </v-menu>
                <!-- <p>Date in ISO format: <strong>{{ date }}</strong></p> -->
              </div>
              <!-- <div>
                                <v-radio-group v-model="tipo_informe">
                                    <v-radio
                                    :key="`simple`"
                                    :label="`Simple`"
                                    :value="`simple`"
                                    ></v-radio>
                                    <v-radio
                                    :key="`detallado`"
                                    :label="`Detallado`"
                                    :value="`detallado`"
                                    ></v-radio>
                                </v-radio-group>
                            </div> -->
            </div>
            <div class="card-body pt-0 pb-3 d-flex justify-content-end">
              <div>
                <v-btn block class="boton font-14 px-9 py-4 my-3" @click="generar()">Generar</v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </v-row>
    </v-container>
    <!--end::Pedidos-->

    <v-snackbar top :timeout="4000" v-model="snackbar.show" color="#4CAF50">
      {{ snackbar.text }}
      <v-btn color="black" text @click="snackbar.show = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import { GET_ORDERS, SELECT_ORDER } from "@/core/services/store/orders.module";
import { GET_PRODUCTS_BY_OWNER_ID } from "@/core/services/store/products.module";
import { GET_SELLER_LOGS } from "@/core/services/store/logs.module";

export default {
  name: "Pedidos",
  components: {},
  data: (vm) => ({
    fecha_inicio: new Date().toISOString().substr(0, 10),
    fecha_fin: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    snackbar: {
      show: false,
      text: "",
    },
    tipo_informe: 1,
  }),
  mounted() {
    this.$store
      .dispatch(GET_ORDERS, this.$store.state.auth.user.seller._id)
      .then((res) => { });
    this.$store
      .dispatch(
        GET_PRODUCTS_BY_OWNER_ID,
        this.$store.state.auth.user.seller._id
      )
      .then((res) => { });
    this.$store
      .dispatch("getSellerLogs", this.$store.state.auth.user.seller._id)
      .then((res) => { });
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    fecha_inicio_formatted() {
      return this.formatDate(this.fecha_inicio);
    },
    fecha_fin_formatted() {
      return this.formatDate(this.fecha_fin);
    },
    ...mapState({
      orders: (state) => state.orders.orders,
      selectedOrder: (state) => state.selectedOrder,
      isFetching: (state) => state.orders.isFetching,
      logs: (state) => state.logs.items,
    }),
    minCurrentMonth() {
      if (this.datesRange.length) {
        let current = new Date(this.datesRange[0]);
        current.setDate(current.getDate() + 1);
        return current.toISOString().slice(0, 10);
      } else {
        return new Date().toISOString().slice(0, 10);
      }
    },
    maxDate() {
      return new Date().toISOString().slice(0, 10);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    generar() {
      // let routeData = this.$router.resolve({name: 'informeSencillo', query: {id: "id", f_inicio: this.fecha_inicio, f_fin: this.fecha_fin}});
      window.open(
        `${window.location.origin}/reports/detailed/${this.$store.state.auth.user.seller._id}/f_inicio/${this.fecha_inicio}/f_fin/${this.fecha_fin}`,
        "_blank"
      );
      // this.$router.push({name: 'simpleReport', params: {id: this.$store.state.auth.user.seller._id, f_inicio: this.fecha_inicio, f_fin: this.fecha_fin}});
    },
  },
};
</script>
<style>
.timeline-item {
  min-height: 28px !important;
}
</style>
